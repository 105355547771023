import React from 'react';
import PropTypes from 'prop-types';

import Helmet from '../project/Helmet';
import withDataHOC from '../dataProvider/withDataHOC';
import ProductsCategories from './ProductCategories';
import CurrentNews from '../news/CurrentNews';
import locationHOC from '../locationProvider/locationHOC';
import AddProduct from './AddProduct';
import ProductTableWrapper from './ProductTableWrapper';
import ProductFilters from './ProductFilters';
import {getQueryParam, getQueryParamNumber} from '../../lib/url';
import {GLOBAL_DATA} from '../../constants/globalData';
import {QUERY_PARAMS, ROUTES} from '../../constants/navigation';
import {Products, CategoryDetails, Location} from '../../constants/propTypesDefinitions';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
import ProductSearchBar from './ProductSearchBar';
import AddCategory from './categoryTree/AddCategory';
import ProductsCategoriesTree from './ProductsCategoriesTree';
import { RIGHTS } from '../../constants/Rights';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import Link from '../navigation/Link';
import {Trans, t} from '@lingui/macro';
import PhoneLink from '../general/PhoneLink';
import EmailLink from '../general/EmailLink';
import ProductCrossReferences from './ProductCrossReferences';
import PersistScroll from '../general/PersistScroll';
import { ENTITY } from '../../constants/modules';
import RightsWrapper from '../sessionProvider/RightsWrapper';

/**
 * @fero
 */

class ProductsDisplay extends React.PureComponent {
    static propTypes = {
        location: Location.isRequired,
        [GLOBAL_DATA.PRODUCTS]: Products.isRequired,
        [GLOBAL_DATA.CATEGORY_DETAILS]: CategoryDetails.isRequired,
    };

    render() {
        const {
            location,
            [GLOBAL_DATA.PRODUCTS]: products,
            [GLOBAL_DATA.CUSTOMERS]: customers,
            [GLOBAL_DATA.CATEGORY_DETAILS]: categoryDetails = {},
            [SESSION_ATTRIBUTES.IS_MOBILE]: isMobile,
            [SESSION_ATTRIBUTES.RIGHTS]: rights,
            [SESSION_ATTRIBUTES.SETTINGS]: settings,
        } = this.props;

        const modules = settings != null && Array.isArray(settings.modules) ? settings.modules : [];
        if(!modules.includes(ENTITY.ESHOP))
        {
            // dummy page
            return <div className="d-flex justify-content-center align-items-center full-size-width full-size-height">
                <div className="text-center">
                    <h1><Trans>Vitajte v systéme ENTITY</Trans></h1>
                    <RightsWrapper from={RIGHTS.MANAGER}>
                        <Trans>Vyberte si z bohatej ponuky <Link className="pl-2 text-dark font-weight-bold" to={ROUTES.ADMINISTRATION_LICENSE}>voliteľných modulov</Link></Trans>
                    </RightsWrapper>
                </div>
            </div>
        }

        const showCrossRefs = getQueryParamNumber(location, QUERY_PARAMS.SEARCH_CROSS_REFS);
        const productGroupId = getQueryParam(location, QUERY_PARAMS.ID_PRODUCT_GROUP);
        const designation = getQueryParam(location, QUERY_PARAMS.DESIGNATION);
        const customerId = getQueryParamNumber(location, QUERY_PARAMS.ID_CUSTOMER);
        const customer = customerId != null && customers != null ? customers.find(c => c.id == customerId) : null;
        
        const hasProducts = products != null && products.lines != null && showCrossRefs != 1;
        const hasFilters = products != null && products.filters != null && products.filters.length > 0;
        const hasSalesman = categoryDetails.salesman != null && rights >= RIGHTS.WORKER && !isMobile;
        const showCategoryDetails = (designation == null);
        const isRoot = productGroupId == null || productGroupId == 'C';
        const pathChecked = products != null && products.path != null && Array.isArray(products.path) ? products.path : [];

        let leftCol = [];
        let mainCol = [];

        if(isMobile)
        {
            // show only product search bar without any header
            mainCol.push(
                <ProductSearchBar key="search"/>
            );

            // show path with add button
            mainCol.push(
                <div key="path" className="d-flex full-size-width justify-content-between">
                    { !isRoot ? 
                        <div className="d-flex flex-wrap">
                            { pathChecked.map((obj, idx) => {
                                return <div key={obj.id} className="text-nowrap">
                                    <Link
                                        className="text-secondary"
                                        to={ROUTES.HOME}
                                        queryParams={{
                                            [QUERY_PARAMS.ID_PRODUCT_GROUP]: obj.id,
                                        }}
                                    >
                                        {obj.name}
                                    </Link>
                                    { idx < pathChecked.length-1 ?
                                        <span className="px-2">/</span> : 
                                        null
                                    }
                                </div>;
                            })}
                        </div>
                        : null
                    }
                    <AddProduct/>
                </div>
            );
        }
        else
        {
            if(settings.homepage_layout == 'b2b')
            {
                // show large search bar in main column
                mainCol.push(
                    <div key="search" className="d-flex align-items-center p-3">
                        <span className="mr-2" style={{fontSize: '150%'}}><Trans>Hľadať produkt:</Trans></span>
                        <ProductSearchBar/>
                        <AddProduct label={<Trans>Vytvoriť nový produkt</Trans>}/>
                    </div>
                );
            }
            else
            {
                // search bar in the left column
                leftCol.push(
                    <div key="search" className="flex-item-static">
                        <ProductSearchBar/>
                    </div>
                );
            }
            
            if(rights >= RIGHTS.MARKETING)
            {
                leftCol.push(
                    <div key="add-category" className="pt-2">
                        <AddCategory/>
                    </div>
                );
            }

            leftCol.push(
                <div key="category-tree" className="flex-item-dynamic-1 p-3 full-size-width overflow-hidden overflow-auto">
                    <ProductsCategoriesTree/>
                </div>
            );
        }

        if(showCrossRefs)
        {
            // only cross references page
            mainCol.push(
                <ProductCrossReferences key="cross-refs"/> 
            );
        }
        else
        {
            // standard view
            if(!isMobile && !hasProducts && settings.homepage_layout != 'b2b')
            {
                mainCol.push(
                    <div key="add-product" className="pt-2 px-3 text-right">
                        <AddProduct 
                            label={<Trans>Vytvoriť nový produkt</Trans>}
                        />
                    </div>
                );
            }

            mainCol.push(
                <ProductFilters
                    key="filters"
                    filters={products.filters}
                    manufacturers={products.manufacturers}
                />
            );
            
            if(showCategoryDetails)
            {
                // news + category details + subcategories
                mainCol.push(
                    <CurrentNews key="news"/>
                );
                
                if(categoryDetails.description_html != null)
                {
                    mainCol.push(
                        <div
                            key="category-description"
                            className="m-4"
                            dangerouslySetInnerHTML={{__html: categoryDetails.description_html}}
                        />
                    );
                }

                if(settings.homepage_layout != 'b2b')
                {
                    mainCol.push(
                        <ProductsCategories
                            key="subcategories"
                            products={products}
                        />
                    );
                }
            }

            if(hasProducts)
            {
                mainCol.push(
                    <div key="products">
                        <ProductTableWrapper
                            products={products}
                        />
                    </div>
                );
            }
            else if(products.total_count != null && products.total_count > 0)
            {
                mainCol.push(
                    <div key="products-overflow" className="text-center">
                        <h3 className="p-3">
                            <Trans>V tejto kategórii sa nachádza</Trans>
                            <span className="font-weight-bold">{' ' + products.total_count + ' '}</span>
                            <Trans>produktov.</Trans>
                        </h3>
                        <Link queryParams={{[QUERY_PARAMS.PRODUCTS_LARGE_CATEGORY]: 1}}>
                            <Trans>Zobraziť (môže to dlhšie trvať)</Trans>
                        </Link>
                    </div>
                )
            }
        }

        if(hasSalesman)
        {
            // add salesman info
            mainCol.push(
                <div key="salesman" className="mb-1 secondary-text-color table-subdata">
                    <span className="ml-4">
                        <Trans>Za kategóriu zodpovedá:</Trans>
                        {' '}
                        <span className="text-dark">{categoryDetails.salesman.fullname}</span>
                    </span>
                    { categoryDetails.salesman.phone != null ? 
                        <span className="ml-2">
                            <Trans>tel.:</Trans>
                            {' '}
                            <PhoneLink phone={categoryDetails.salesman.phone}/>
                        </span> : null
                    }
                    { categoryDetails.salesman.email != null ?
                        <span className="ml-2">
                            <Trans>e-mail:</Trans>
                            {' '}
                            <EmailLink email={categoryDetails.salesman.email}/>
                        </span> : null
                    }
                </div>
            );
        }

        return <React.Fragment>
            <Helmet
                title={ !isRoot ? 
                    categoryDetails.name 
                    : 
                    ( customer != null ? customer.name : '')
                }
                description={categoryDetails.description_short}
            />
            <div className={"d-flex full-size-height" + (isMobile ? " flex-column" : "")}>
                { leftCol.length > 0 ? 
                    <div className="d-flex flex-column flex-item-static full-size-height left-navigation">
                        {leftCol}
                    </div>
                    : null
                }
                <PersistScroll 
                    className={"px-3 " + (isMobile ? "full-size-width" : "flex-item-dynamic-1 full-size-height")}
                    scrollParam={QUERY_PARAMS.PRODUCTS_SCROLL}
                >
                    {mainCol}
                </PersistScroll>
            </div>
        </React.Fragment>;
    }

}

export default locationHOC(
    withSessionHOC([SESSION_ATTRIBUTES.IS_MOBILE, SESSION_ATTRIBUTES.RIGHTS, SESSION_ATTRIBUTES.SETTINGS])(
        withDataHOC([GLOBAL_DATA.PRODUCTS, GLOBAL_DATA.CUSTOMERS, GLOBAL_DATA.CATEGORY_DETAILS])(ProductsDisplay)
    )
);